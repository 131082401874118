import "./Footer.less";

function Footer() {
  const LicenseList1 = [
    "互联网药品信息服务资格证书：（湘）-经营性-2022-0103号",
    "第二类医疗器械经营备案凭证：湘长市场监械经营备20222393号",
  ];

  const LicenseList2 = [
    "网络文化经营许可证：湘网文（2022）3204-110号",
    "出版物经营许可证：新出发岳文字第4301042022146号",
    "广播电视节目制作经营许可证：（湘长）字第00104号",
  ];

  return (
    <div className="footer">
      <div className="fixed-box">
        <h2>赞友商城</h2>
        <div className="footer-info">
          <div className="footer-left">
            <p>
              湖南赞友数字科技有限公司成立于2021年8月，总部设在中国移动互联网第五城的湖南省长沙市高新区，公司诞生在移动互联网时代、国家“乡村振兴”战略背景下，是一家致力于使用互联网电商平台为乡村振兴提供产业扶持、产业培育、乡村市场拓展、产品销售、运营维护、供应链管理于一体的多元化高新电子商务服务企业。
            </p>
          </div>
          <div className="footer-middle">
            <p>联系邮箱：zanyou2021@126.com </p>
            <p>
              联系地址：长沙高新开发区延龙路72号长沙市云海通讯设备有限公司2栋6层
            </p>
          </div>
          <div className="footer-right">
            <p>联系电话：400 - 6896 - 005</p>
          </div>
        </div>
        <div className="footer-lodge">
          <a
            href="https://beian.mps.gov.cn/#/query/webSearch?code=43019002002105"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://beian.mps.gov.cn/img/logo01.dd7ff50e.png"
              width="12px"
            />
            <span> 湘公网安备43019002002105号</span>
          </a>
          <i className="footer-i">|</i>
          <a href="https://beian.miit.gov.cn" target="_blank">
            {/* <img src={Bg} alt="赞友数字科技" width="20px" height="20px"/>
                        <span>湘公网安备 8552455262252号</span> */}
            <span>湘ICP备2021015216号-1</span>
          </a>
          <i className="footer-i">|</i>
          <span className="footer-span">
            © 湖南赞友数字科技有限公司 版权所有
          </span>
          <i className="footer-i">|</i>
          <span className="footer-span">
            增值电信业务经营许可证编号:湘B2-20210396
          </span>
        </div>
        <div className="footer-lodge">
          {LicenseList1.map((item, index) => (
            <span className="footer-span" key={index}>
              {item}
              {index === LicenseList1.length - 1 ? null : (
                <i className="footer-i">|</i>
              )}
            </span>
          ))}
        </div>
        <div className="footer-lodge">
          {LicenseList2.map((item, index) => (
            <span className="footer-span" key={index}>
              {item}
              {index === LicenseList2.length - 1 ? null : (
                <i className="footer-i">|</i>
              )}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Footer;
